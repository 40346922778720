import Container from "@material-ui/core/Container"
import React from "react"
import styled from "styled-components"
import {
  PageBuilderActiviteitButtonsGroupFragment,
  PageBuilderButtonsGroupFragment,
  PageBuilderErvaringButtonsGroupFragment,
  PageBuilderNieuwsButtonsGroupFragment,
  PageBuilderVacatureButtonsGroupFragment,
} from "../../../__generated__/graphql-gatsby"
import Button from "./button"

interface IProps {
  block:
    | PageBuilderButtonsGroupFragment
    | PageBuilderErvaringButtonsGroupFragment
    | PageBuilderNieuwsButtonsGroupFragment
    | PageBuilderActiviteitButtonsGroupFragment
    | PageBuilderVacatureButtonsGroupFragment
}

const Wrapper = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  gap: ${({ theme }) => theme.spacing(6)}px;
`

const ButtonGroup: React.FC<IProps> = ({ block }) => {
  const { buttons } = block

  if (!buttons || buttons.length < 1) return null

  return (
    <Wrapper maxWidth="lg">
      {buttons.map((node, i) => (
        <Button
          key={i}
          title={node?.button?.title}
          url={node?.button?.url || "#"}
          target={node?.button?.target}
        />
      ))}
    </Wrapper>
  )
}

export default ButtonGroup
