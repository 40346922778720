import MuiButton from "@material-ui/core/Button"
import React from "react"
import { getLinkProps } from "../../../helpers"

interface IProps {
  title: Maybe<string>
  url: string | null
  target: Maybe<string>
}

const Button: React.FC<IProps> = ({ title, url, target }) => {
  return (
    <MuiButton
      color="secondary"
      variant="contained"
      size="large"
      {...getLinkProps(url, { target: target })}
    >
      {title}
    </MuiButton>
  )
}

export default Button
